import { Box, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import React from "react";

const locationAndTimeData = [
    {
        time: "Stone throwing distance from",
        location: "Model Town's Entrance",
    },
    {
        time: "2-minute drive from",
        location: "Kalma Chowk",
    },
    {
        time: "2-minute drive from",
        location: "Barkat Market",
    },
    {
        time: "2-minute drive from",
        location: "Model Town Park",
    },
    {
        time: "5-minute drive from",
        location: "Punjab University/ Sheikh Zayed Hospital",
    },
    {
        time: "5-minute drive from",
        location: "Liberty Market",
    },

    {
        time: "6-minute drive from",
        location: "Gaddafi Stadium",
    },
    {
        time: "25-minute drive from",
        location: "Allama Iqbal Airport",
    },
];

const LocationAndTime = ({ time, location }) => {
    return (
        <Box
            className="mt-md-0 mt-3"
            sx={{ borderLeft: "3px solid #40276C" }}
            pl={1}
        >
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
                {time}
            </Typography>
            <Typography
                variant="body1"
                className="fw-bold"
                sx={{ fontSize: "18px" }}
            >
                {location}
            </Typography>
        </Box>
    );
};

export default function Location() {
    return (
        <Box className="location-highlights-bg" id="location">
            <Container>
                <Grid container>
                    <Grid
                        items
                        xs={12}
                        md={6}
                        lg={6}
                        className="location-content-section"
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: "44px",
                            }}
                            className="text-uppercase"
                        >
                            Location
                        </Typography>
                        <Typography
                            mt={2}
                            variant="body1"
                            sx={{
                                fontSize: "34px",
                            }}
                            className="text-uppercase fw-bold"
                        >
                            Hightlights
                        </Typography>
                        <Typography
                            my={3}
                            variant="body1"
                            sx={{ fontSize: "16px" }}
                        >
                            Indigo Signature is centrally located at 15 Sher
                            Shah Block Garden Town, the centre of Lahore. This
                            prestigious locality is home to the best
                            educational, commercial, healthcare and recreational
                            facilities in the city.
                        </Typography>
                        <Typography
                            my={3}
                            pl={1}
                            variant="body1"
                            sx={{
                                fontSize: "16px",
                                borderLeft: "3px solid #40276C",
                            }}
                        >
                            Residents of this vicinity also enjoy an exclusive
                            sense of robust community living. The project's
                            location is dissected as:
                        </Typography>

                        <Grid container>
                            {locationAndTimeData.map((data, index) => (
                                <Grid item xs={12} mt={2} md={6} key={index}>
                                    <LocationAndTime
                                        time={data.time}
                                        location={data.location}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Box mt={5}>
                            <a
                                href="https://goo.gl/maps/3tKdizhx2w8FsEhX8"
                                target="_blank"
                                className="btn btn-lg y-btn text-uppercase "
                            >
                                get direction on map
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
