import React from "react";
import { Box, Grid } from "@mui/material";
import Container from "@mui/material/Container";
import Carousel from "react-material-ui-carousel";
import Img1 from "../../assets/img/mainslider/1.jpg";
import next from "../../assets/img/mainslider/arrow_next.jpg";
import prev from "../../assets/img/mainslider/arrow_prev.jpg";
import side1 from "../../assets/img/mainslider/side1.jpg";
import side2 from "../../assets/img/mainslider/side2.jpg";
import VideoPopup from "../VideoPopup";

export default function Main() {
    var items = [
        {
            img: Img1,
        },
    ];
    function Item(props) {
        return (
            <img
                src={props.item.img}
                style={{ objectFit: "cover", width: "100%" }}
                alt="Slider"
            />
        );
    }
    return (
        <section>
            <Grid container alignItems="top">
                <Grid item xs={12} md={12}>
                    <Container>
                        <Grid container rowGap={3} alignItems="top">
                            <Grid item xs={6} md={6}>
                                <img
                                    className=" d-lg-inline d-block mx-auto"
                                    src={side1}
                                    width={130}
                                    height={80}
                                    alt=""
                                />
                                <p
                                    className="imghead mb-0 text-lg-start text-center mt-2"
                                    style={{
                                        fontSize: "12px",
                                    }}
                                >
                                    Studio, 1, 2 & 3 <br />
                                    Bedroom Apartments
                                </p>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <img
                                    className=" d-lg-inline d-block mx-auto"
                                    src={side2}
                                    width={130}
                                    height={80}
                                    alt=""
                                />
                                <p
                                    className="imghead mb-0 text-lg-start text-center mt-2"
                                    style={{
                                        fontSize: "12px",
                                    }}
                                >
                                    Single-storey & <br /> Duplex Penthouses
                                </p>
                            </Grid>
                        </Grid>
                        <Box
                            className="d-flex"
                            sx={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: {
                                    lg: "flex-start",
                                    md: "center",
                                    sm: "center",
                                    xs: "center",
                                },
                                columnGap: "10px",
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: {
                                        lg: "0",
                                        // md: "20px",
                                        // sm: "20px",
                                        // xs: "20px",
                                    },
                                    marginBottom: {
                                        lg: "0",
                                        // md: "20px",
                                        // sm: "20px",
                                        // xs: "20px",
                                    },
                                    columnGap: "10px",
                                }}
                            >
                                <a
                                    href="/Catalog-Indigo-Signature.pdf"
                                    className="text-uppercase mt-2 d-inline-block text-uppercase text-decoration-none"
                                    style={{
                                        fontSize: "12px",
                                        background: "#40276c",
                                        color: "white",
                                        padding: "0 30px",
                                        borderRadius: "50px",
                                    }}
                                >
                                    download catalogue
                                </a>
                            </Box>
                            <Box>
                                <a
                                    href="#watch-tvc"
                                    className="text-uppercase mt-2 text-decoration-none text-uppercase d-inline-block"
                                    style={{
                                        fontSize: "12px",
                                        background: "#40276c",
                                        color: "white",
                                        padding: "0px 30px",
                                        borderRadius: "50px",
                                    }}
                                >
                                    watch tcv
                                </a>
                            </Box>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
        </section>
    );
}
