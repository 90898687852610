import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    Typography,
} from "@mui/material";
import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";

const points = [
    {
        point: `Indigo Signature is <span>approved by Lahore Development Authority (LDA),</span>  thus guaranteeing the safety of your investment `,
    },
    {
        point: `A rental of <span>5-7%</span>  is expected annually`,
    },
    {
        point: `Real estate prices in the vicinity <span>increased by 40-50%</span>  in the last 3 years, and are projected to increase by <span>50-60% during the next 3 years</span>`,
    },
    {
        point: `An <span className="sem">optimal location</span>  in 15 Sher Shah Block Garden Town, Lahore; opposite Model Town’s entrance, directly connecting to the prime areas of the city`,
    },
    {
        point: `Offers <span>premium facilities at a much lower price</span>  than other real estate projects in the vicinity`,
    },
    {
        point: `Indigo Signature's <span>unique design,</span> coupled with the <span>highest standards of construction,</span>  make it the most desirable destination for your future home`,
    },
    {
        point: `A <span>contemporary design</span>  palate, elevated by the amenities that surround the apartments, makes Indigo Signature <span>a perfect home for a modern family</span>`,
    },
    {
        point: `<span>Being constructed by one of the most reputable real estate developers in the country,</span>  Indigo Developments, the project is expected to be finished and delivered in a timely fashion`,
    },
    {
        point: `The <span>ever-increasing demand for vertical developments in Lahore,</span>  owing to the change in customer preferences, make this project more desirable for investors`,
    },
    {
        point: `Search volumes for apartments increased by <span>185%</span> between <span>January 2018</span> and <span>September 2020</span>`,
    },
];

export default function MobInvestment() {
    return (
        <div>
            <Box>
                <Container>
                    <Grid container alignItems="center">
                        <Grid items xs={12} md={12} lg={7}>
                            <Box sx={{ marginTop: "20px" }}>
                                <Box>
                                    {points.map((item, index) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "top",
                                            }}
                                            flexDirection="row"
                                        >
                                            <Box>
                                                <AiFillCheckCircle
                                                    color="#40276C"
                                                    size={10}
                                                />
                                            </Box>
                                            <Box pl={2}>
                                                <p
                                                    key={index}
                                                    className="position-relative"
                                                    style={{
                                                        fontSize: "12px",
                                                        marginTop: "3px",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.point,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
}
