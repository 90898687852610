import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { MdLocationPin } from "react-icons/md";
import { BsFillBuildingFill } from "react-icons/bs";
import { FaRegThumbsUp } from "react-icons/fa";
import { RiEarthquakeFill } from "react-icons/ri";
import { GiCctvCamera } from "react-icons/gi";
import { GiTrafficLightsRed } from "react-icons/gi";

import Pic1 from "../assets/img/project-details/Picture1.png";
import Pic2 from "../assets/img/project-details/Picture2.png";
import Pic3 from "../assets/img/project-details/Picture3.png";
import Pic4 from "../assets/img/project-details/Picture4.png";
import Pic5 from "../assets/img/project-details/Picture5.png";
import Pic6 from "../assets/img/project-details/Picture6.png";
import Pic7 from "../assets/img/project-details/Picture7.png";
import Pic8 from "../assets/img/project-details/Picture8.png";
import Pic9 from "../assets/img/project-details/Picture9.png";

import VideoButtonImg from "../assets/img/hightlights-section/3D-button.png";

const highlightsData = [
    {
        img: Pic4,
        text: "Centrally located in 15 Sher Shah Block Garden Town, Lahore",
    },
    {
        img: Pic3,
        text: "14-storey structure being built on an area of 4 kanals",
    },
    {
        img: Pic2,
        text: "Approved by Lahore Development Authority (LDA)",
    },
    {
        img: Pic1,
        text: "Earthquake resistant structure",
    },
    {
        img: Pic5,
        text: "Fast elevator facility",
    },
    {
        img: Pic6,
        text: "Round the clock Security & Surveillance",
    },
    {
        img: Pic7,
        text: "24/7 Power Backup & Facilities Management",
    },
    {
        img: Pic8,
        text: "2 basement floors for parking",
    },
];

export default function Highlights() {
    return (
        <Box className="highlights-bg" id="project">
            <Typography
                variant="body1"
                className="text-white"
                sx={{ fontSize: "44px" }}
            >
                PROJECT
            </Typography>
            <Box mt={2}>
                <Typography
                    variant="body1"
                    className="text-white fw-bold text-uppercase"
                    sx={{ fontSize: "34px" }}
                >
                    Highlights
                </Typography>
            </Box>
            <Box className="highlights-grid" mt={3}>
                {highlightsData.map((item, index) => (
                    <Box className="highlights-card" key={{ index }}>
                        <img
                            src={item.img}
                            style={{
                                width: "50px",
                                height: "50px",
                                display: "block",
                                margin: "0 auto",
                                marginBottom: "15px",
                            }}
                        />
                        <Typography
                            variant="body1"
                            className="text-white text-center"
                            sx={{
                                fontSize: "16px",
                                width: {
                                    lg: "90%",
                                    md: "90%",
                                    sm: "80%",
                                    xs: "80%",
                                },
                                margin: "0 auto",
                            }}
                        >
                            {item.text}
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Box mt={2} className="hightlights-button-group">
                <a
                    href="https://earth.google.com/web/search/Indigo+Signature+Apartments,+New,+Shershah+Block+Garden+Town,+Lahore/@31.49595245,74.32311967,230.77939032a,82.57727268d,35y,-165.29661268h,44.99489809t,359.99999999r/data=CrABGoUBEn8KJTB4MzkxOTA1NDA2YjFmMThhNzoweDg5YWQwZWUxOTUxM2FmNTkZVHcbivF-P0Ah9D5EWa6UUkAqREluZGlnbyBTaWduYXR1cmUgQXBhcnRtZW50cywgTmV3LCBTaGVyc2hhaCBCbG9jayBHYXJkZW4gVG93biwgTGFob3JlGAEgASImCiQJXhJblHaCP0ARdEa6UdWAP0AZCtg6icGWUkAh3pNGHbaVUkA "
                    target="_blank"
                    className="icon-button text-uppercase d-inline-block text-decoration-none"
                >
                    <img src={VideoButtonImg} />
                    watch 3d model
                </a>
                <a
                    href="/Catalog-Indigo-Signature.pdf"
                    target="_blank"
                    className="white-button ms-lg-2 mt-md-0 mt-2 text-uppercase text-decoration-none"
                    style={{
                        padding: "14px 35px",
                    }}
                >
                    download catalogue
                </a>
            </Box>
        </Box>
    );
}
