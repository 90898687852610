import React, { useRef, useState } from "react";
// Import Swiper React components
import Slider from "react-slick";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Img11 from "../assets/img/price/price-plan-3.png";
import Img12 from "../assets/img/price/price-plan-2.png";
import Img1 from "../assets/img/lavish/1.jpg";

import Img2 from "../assets/img/lavish/2.jpg";

import Img3 from "../assets/img/lavish/3.jpg";
import Img4 from "../assets/img/lavish/4.jpg";
// import required modules
import { Box, Container, Grid, Typography } from "@mui/material";

import { RiSkipBackFill, RiSkipForwardFill } from "react-icons/ri";
import LimitedOffer from "../assets/img/limited-offer/limited-offer.jpg";

import S1 from "../assets/img/lavish/5.jpg";
import S2 from "../assets/img/lavish/6.jpg";

import Email from "./Email";

const data = [
  {
    title: "Grand <br /> Lobby",
    image: Img1,
  },
  {
    title: "Rooftop <br /> Recreational Area",
    image: Img2,
  },
  {
    title: "Gymnasium & <br /> Fitness Suite",
    image: Img3,
  },
  {
    title: "Swimming <br /> Pool",
    image: Img4,
  },
  {
    title: "2-floor <br /> Basement Parking",
    image: S1,
  },
  {
    title: "High-speed <br /> Lifts",
    image: S2,
  },
];

const iframeSrcs2 = [
  {
    src: "https://www.youtube.com/embed/63NHXTR2Vj0",
  },
  {
    src: "https://www.youtube.com/embed/bJKM-2lYkvo",
  },
];

const iframeSrcs = [
  {
    src: "https://www.youtube.com/embed/bBK_qqZgU98",
  },
  {
    src: "https://www.youtube.com/embed/Hh8169keM-c",
  },
  {
    src: "https://www.youtube.com/embed/UqS4qLKS2yo",
  },
];

const ImagesAndVideo = ({ src, data, mt = "-66px" }) => {
  return (
    <div className="mb-2">
      <img src={src} className="img-fluid" />
      <Box mt={mt} mb={2}>
        <div className="d-flex">
          {data.map((item, index) => (
            <iframe
              key={index}
              width={data.length > 2 ? "380" : "571"}
              height={data.length > 2 ? "180" : "220"}
              src={item.src}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default function MySlider() {
  const settings = {
    customPaging: function (i) {
      return <a dangerouslySetInnerHTML={{ __html: data[i].title }} />;
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      <Box className="levish-section-bg" id="facilites">
        <Box className="container-fluid">
          <Grid container alignItems={"center"}>
            <Grid item lg={3}>
              <Typography
                className="text-white mb-0"
                sx={{
                  fontSize: "44px",
                }}
              >
                Lavish
              </Typography>
              <Typography
                variant="body1"
                className="fw-bold text-uppercase text-white"
                sx={{
                  fontSize: "44px",
                }}
              >
                facilities <br />& amenities
              </Typography>
              <Typography
                variant="body1"
                className="text-white"
                sx={{
                  fontSize: "18px",
                  marginBottom: "60px",
                }}
              >
                Facilities and amenities that are fit for a modern, luxurious
                lifestyle
              </Typography>
              <Email />
            </Grid>
            <Grid lg={9} className="overflow-hidden">
              <Box pt={6}>
                <Box className="position-relative">
                  <Slider {...settings} ref={sliderRef}>
                    {data.map((item, index) => (
                      <div key={index}>
                        <img
                          src={item.image}
                          style={{
                            width: "300px",
                            height: "500px",
                            objectFit: "cover",
                          }}
                          alt={index}
                        />
                      </div>
                    ))}
                  </Slider>

                  <Box mt={4} mb={4} className="d-flex " ml={5}>
                    <div
                      className="text-uppercase font-bold arrow-btn align-items-center d-flex"
                      onClick={previous}
                    >
                      <RiSkipBackFill />
                      <span className="ms-1">prev</span>
                    </div>
                    <div
                      className="text-uppercase font-bold arrow-btn mx-2 align-items-center d-flex"
                      onClick={next}
                    >
                      <span className="me-1">next</span>
                      <RiSkipForwardFill />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={2} id="price">
        <Container>
          {/* <Box className="d-flex align-items-center justify-content-between">
            <Box>
              <Typography
                className="mb-0"
                sx={{
                  fontSize: "34px",
                  fontWeight: 700,
                }}
              >
                Price Planss
              </Typography>
            </Box>
            <Box>
              <img
                src={LimitedOffer}
                style={{ width: "200px", height: "70px" }}
              />
            </Box>
          </Box> */}

          <ImagesAndVideo src={Img12} data={iframeSrcs} mt={-10} />
          <ImagesAndVideo src={Img11} data={iframeSrcs2} />

          <Box className="d-flex  justify-content-center">
            <a
              href="/payment-plan.pdf"
              target="_blank"
              className="icon-button text-decoration-none text-uppercase"
            >
              download price plan
            </a>
          </Box>
        </Container>
      </Box>
    </>
  );
}
