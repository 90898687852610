import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, FreeMode, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Img1 from "../assets/img/verticalSlider/1.jpg";
import Img2 from "../assets/img/verticalSlider/2.jpg";
import Img3 from "../assets/img/verticalSlider/3.jpg";
import { Box, Container, Grid, Typography } from "@mui/material";

import S1 from "../assets/img/slider-4/1.png";
import S2 from "../assets/img/slider-4/2.png";

export default function VerticalSlider() {
    // store thumbs swiper instance
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const slides = [
        {
            img: Img1,
        },
        {
            img: Img2,
        },
        {
            img: Img3,
        },
        {
            img: S1,
        },
        {
            img: S2,
        },
    ];
    return (
        <Box id="construction" sx={{ marginTop: "70px" }}>
            <Box className="bgvertical">
                <Container>
                    <Grid container>
                        <Typography
                            variant="body1"
                            className="fw-bold text-uppercase text-white"
                            sx={{
                                fontSize: "34px",
                            }}
                            mb={5}
                        >
                            CONSTRUCTION UPDATES
                        </Typography>
                        <Grid items xs={12} md={9} lg={9}>
                            <Swiper
                                thumbs={{ swiper: thumbsSwiper }}
                                style={{
                                    "--swiper-navigation-color": "#fff",
                                    "--swiper-pagination-color": "#fff",
                                }}
                                spaceBetween={20}
                                navigation={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper2"
                            >
                                {slides.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <img src={item.img} alt={index} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Grid>
                        <Grid items xs={12} md={3} lg={3}>
                            <Box
                                px={1}
                                py={1}
                                maxHeight={600}
                                overflow={"hidden"}
                                bgcolor={"#00000080"}
                                style={{}}
                            >
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={5}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper"
                                    direction="vertical"
                                >
                                    {slides.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={item.img} alt={index} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}
