import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    Typography,
} from "@mui/material";
import React from "react";
import Img1 from "../assets/img/beam-investment/video-icon-bg.jpg";
import VideoIcon from "../assets/img/beam-investment/video-button-icon.png";
import VideoPopup from "./VideoPopup";

const points = [
    `Indigo Signature is <span>approved by Lahore Development Authority (LDA),</span>  thus guaranteeing the safety of your investment `,
    `A rental of <span>5-7%</span>  is expected annually`,
    `Real estate prices in the vicinity <span>increased by 40-50%</span>  in the last 3 years, and are projected to increase by <span>50-60% during the next 3 years</span>`,
    `An <span className="sem">optimal location</span>   Garden Town, Lahore directly connecting Indigo Signature to the prime areas of the city.`,
    `Offers <span> exceptional facilities at a much lower price</span>  than other real estate projects in the vicinity`,
    `Indigo Signature's  <span>unique design,</span> coupled with the <span>highest standards of construction,</span>  make it the most desirable destination for your future home`,
    `A <span>contemporary design</span>  palate, elevated by the amenities that surround the apartments, makes Indigo Signature <span>a perfect home for a modern family</span>`,
    `<span>Being constructed by one of the most reputable real estate developers in the country,</span>  Indigo Developers, the project is expected to be finished and delivered in time.`,
    `The <span>ever-increasing demand for vertical developments in Lahore,</span>  owing to the change in customer preferences, make this project more desirable for investors`,
    `The <span> ever-increasing demand for vertical developments in Lahore </span>, owing to the change in customer preferences, makes this project more desirable for investors`,
    `Pakistan requires over <span>700,000 new residential units</span> annually due to the increasing level of housing shortfall, and reasonably priced projects like Indigo Signature play a pivotal role in addressing this requirement.`,
];

export default function Investment() {
    return (
        <Box mt={5} id="why">
            <Container>
                <Grid container alignItems="center">
                    <Grid
                        items
                        xs={12}
                        md={6}
                        lg={7}
                        className="location-content-section"
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: "44px",
                            }}
                            className="text-uppercase"
                        >
                            a beaming
                        </Typography>
                        <Typography
                            mt={2}
                            variant="body1"
                            sx={{
                                fontSize: "34px",
                            }}
                            className="text-uppercase fw-bold"
                        >
                            Investment
                        </Typography>
                        <Box sx={{ marginTop: "40px" }}>
                            <List
                                component="ul"
                                className="investment-points "
                                style={{
                                    borderLeft: "2px solid #40276C",
                                    listStyle: "none",
                                }}
                            >
                                {points.map((point, index) => (
                                    <li
                                        key={index}
                                        className="position-relative"
                                        dangerouslySetInnerHTML={{
                                            __html: point,
                                        }}
                                    />
                                ))}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <Box sx={{ position: "relative" }}>
                            <img
                                style={{ width: "100%", height: "auto" }}
                                src={Img1}
                                alt="background-img"
                            />
                            <VideoPopup>
                                <img
                                    src={VideoIcon}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        cursor: "pointer",
                                    }}
                                />
                            </VideoPopup>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
