import { Box } from "@mui/material";
import React from "react";

export default function MovWatchTVC() {
    return (
        <Box
            sx={{
                marginTop: "32px",
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
            <iframe
                width="100%"
                height="230"
                src="https://www.youtube.com/embed/gwWph9amKRA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        </Box>
    );
}
