import React from "react";

import { MdWifiCalling3 } from "react-icons/md";
import Whatsapp from "../../assets/img/whatsapp-icon/whatsapp.png";
import EmailMobile from "../Email";

export default function StickyBottomSection() {
    return (
        <div
            className="p-3 bg-white"
            style={{
                position: "sticky",
                zIndex: 1000,
                bottom: "-1px",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{ columnGap: "10px" }}
            >
                <div>
                    <EmailMobile forMobile />
                </div>
                <div>
                    <button
                        style={{
                            background: "transparent",
                            borderRadius: "4px",
                            padding: "2px 10px",
                            border: "1px solid black",
                        }}
                    >
                        <a href="https://wa.me/923303022222">
                            <img
                                src={Whatsapp}
                                style={{ width: "35px", height: "35px" }}
                            />
                        </a>
                    </button>
                </div>
                <div>
                    <button
                        style={{
                            background: "transparent",
                            borderRadius: "4px",
                            border: "1px solid black",
                        }}
                    >
                        <a
                            href="tel:03303022222"
                            className="d-flex justify-content-center align-items-center text-decoration-none"
                            style={{
                                height: "35px",
                                width: "90px",
                                color: "#000",
                            }}
                        >
                            <MdWifiCalling3 />
                            <span
                                className="d-inline-block"
                                style={{ marginLeft: "10px" }}
                            >
                                Call
                            </span>
                        </a>
                    </button>
                </div>
            </div>
        </div>
    );
}
