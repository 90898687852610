import { Button, Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { MdLocationPin } from "react-icons/md";
import { BsFillBuildingFill } from "react-icons/bs";
import { FaRegThumbsUp } from "react-icons/fa";
import { RiEarthquakeFill } from "react-icons/ri";
import { GiCctvCamera } from "react-icons/gi";
import { FaParking } from "react-icons/fa";
import { GiPowerGenerator } from "react-icons/gi";
import { GiElevator } from "react-icons/gi";

const highlightsData = [
    {
        svg: <MdLocationPin />,
        text: "Centrally located in 15 Sher Shah Block Garden Town, Lahore",
    },
    {
        svg: <BsFillBuildingFill />,
        text: "14-storey structure being built on an area of 4 kanals",
    },
    {
        svg: <FaRegThumbsUp />,
        text: "Approved by Lahore Development Authority (LDA)",
    },
    {
        svg: <RiEarthquakeFill />,
        text: "Earthquake resistant structure",
    },
    {
        svg: <GiElevator />,
        text: "Fast elevator facility",
    },
    {
        svg: <GiCctvCamera />,
        text: "Round the clock Security & Surveillance",
    },
    {
        svg: <GiPowerGenerator />,
        text: "24/7 Power Backup & Facilities Management",
    },
    {
        svg: <FaParking />,
        text: "2 basement floors for parking",
    },
];

export default function MobileHighlights() {
    return (
        <>
            <div className="mt-3" id="projectHighlights">
                <Box px={3}>
                    <Typography
                        variant="body1"
                        className="fw-bold"
                        sx={{ fontSize: "14px" }}
                    >
                        Project Highlights
                    </Typography>

                    <Box
                        className=" highlights-grid"
                        mt={3}
                        sx={{
                            columnGap: "10px",
                            rowGap: "10px",
                        }}
                    >
                        {highlightsData.map((item, index) => (
                            <Box className="highlights-card" key={{ index }}>
                                {item.svg}
                                <Typography
                                    variant="body1"
                                    className="text-white text-center"
                                    sx={{
                                        fontSize: "10px",
                                        width: {
                                            lg: "90%",
                                            md: "90%",
                                            sm: "90%",
                                            xs: "90%",
                                        },
                                        margin: "0 auto",
                                    }}
                                >
                                    {item.text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        flex
                        sx={{
                            width: "80%",
                            margin: "0 auto",
                            marginTop: "20px",
                        }}
                    >
                        <Box mt={2} textAlign="center">
                            <a
                                href="/Catalog-Indigo-Signature.pdf"
                                target="_blank"
                                className="btn btn-lg y-btn "
                            >
                                Download Catalogue
                            </a>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    );
}
