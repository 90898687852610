import React, { useRef, useState } from "react";
// Import Swiper React components
import Slider from "react-slick";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Img1 from "../../assets/img/lavish/1.jpg";
import Img2 from "../../assets/img/lavish/2.jpg";
import Img3 from "../../assets/img/lavish/3.jpg";
import Img4 from "../../assets/img/lavish/4.jpg";
// import required modules
import { Pagination, Navigation, Thumbs, FreeMode } from "swiper";
import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { RiSkipBackFill, RiSkipForwardFill } from "react-icons/ri";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function MobMySlider({ className, data = [], id }) {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    const sliderRef = useRef(null);

    return (
        <Box
            sx={{
                marginTop: data[0]?.marginTop ? "32px" : 0,
                width: data[0]?.width ? "80%" : "100%",
                marginLeft: data[0]?.width ? "auto" : "auto",
                marginRight: data[0]?.width ? "auto" : "auto",
            }}
            id={id}
        >
            <Carousel
                showArrows={true}
                className={className}
                style={{
                    background: "red",
                    marginTop: "16px",
                }}
            >
                {data.map((item, index) => (
                    <div key={index}>
                        <img
                            src={item.image}
                            alt={index}
                            style={{
                                height: item.height,
                                objectFit: "cover",
                            }}
                        />
                    </div>
                ))}
            </Carousel>
        </Box>
    );
}
