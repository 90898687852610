import MainImg1 from "../../assets/img/mainslider/1.jpg";
import MainImg2 from "../../assets/img/slider-1/1.png";
import MainImg3 from "../../assets/img/mainslider/2.png";

import IV1 from "../../assets/img/gallery/gallery.jpg";
import IV2 from "../../assets/img/slider-2/1.png";
import IV3 from "../../assets/img/slider-2/2.png";

import L1 from "../../assets/img/lavish/1.jpg";
import L2 from "../../assets/img/lavish/2.jpg";
import L3 from "../../assets/img/lavish/3.jpg";
import L4 from "../../assets/img/lavish/4.jpg";
import L5 from "../../assets/img/lavish/5.jpg";
import L6 from "../../assets/img/lavish/6.jpg";
// import L7 from "../../assets/img/slider-3/3.png";
// import L8 from "../../assets/img/slider-3/4.png";

import C1 from "../../assets/img/verticalSlider/1.jpg";
import C2 from "../../assets/img/verticalSlider/2.jpg";
import C3 from "../../assets/img/verticalSlider/3.jpg";
import C4 from "../../assets/img/slider-4/1.png";
import C5 from "../../assets/img/slider-4/2.png";

export const MainSliderImages = [
    {
        image: MainImg1,
        height: "450px",
    },
    {
        image: MainImg2,
        height: "450px",
    },
    {
        image: MainImg3,
        height: "450px",
    },
];

export const ImageAndVideos = [
    {
        image: IV1,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: IV2,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: IV3,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
];
export const Levish = [
    {
        image: L1,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: L2,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: L3,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: L4,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: L5,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: L6,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    // {
    //     image: L7,
    //     height: "175px",
    //     width: "80%",
    //     marginTop: true,
    // },
    // {
    //     image: L8,
    //     height: "175px",
    //     width: "80%",
    //     marginTop: true,
    // },
];

export const Construction = [
    {
        image: C1,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: C2,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: C3,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: C4,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
    {
        image: C5,
        height: "175px",
        width: "80%",
        marginTop: true,
    },
];
