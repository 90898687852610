import React from "react";
import Main from "./mobileVersion/Main";
import MobileHighlights from "./mobileVersion/MobileHighlights";
import { Box } from "@mui/material";
import MobLocation from "./mobileVersion/MobLocation";
import MobInvestment from "./mobileVersion/MobInvestment";
import MobMySlider from "./mobileVersion/MobMySlider";
import MobPrice from "./mobileVersion/MobPrice";
import ArrowSliders from "./mobileVersion/ArrowSliders";

import { MainSliderImages } from "./mobileVersion/SliderImages";
import { ImageAndVideos } from "./mobileVersion/SliderImages";
import { Levish } from "./mobileVersion/SliderImages";
import { Construction } from "./mobileVersion/SliderImages";
import MovWatchTVC from "./mobileVersion/MovWatchTVC";
import MobileStickyNav from "./mobileVersion/MobileStickyNav";

export default function MobileVersion() {
  const [showNavbar, setShowNavbar] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Adjust the scroll threshold as needed
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  function Title({ title, id }) {
    return (
      <div
        id={id || ""}
        mt={2}
        style={{
          background: "#40276C",
          fontSize: "16px",
          fontWeight: 700,
          color: "#fff ",
          padding: "10px 20px",
        }}
      >
        {title}
      </div>
    );
  }
  return (
    <>
      {/* <MobileStickyNav /> */}
      <nav
        id="navbar-example2"
        className={`navbar sticky-top d-flex justify-content-center ${showNavbar ? 'd-block' : 'd-none'}`}
        style={{
          background: "#40276C",
        }}
      >
        <ul className="nav nav-pills d-flex justify-content-center m-0 p-0">
          <li
            className="nav-item"
            style={{
              listStyle: "none",
            }}
          >
            <a
              className="nav-link text-uppercase text-white"
              href="#projectHighlights"
              data-target="#projectHighlights"
            >
              OVERVIEW
            </a>
          </li>
          <li
            className="nav-item"
            style={{
              listStyle: "none",
            }}
          >
            <a
              className="nav-link text-uppercase text-white"
              href="#location-highlights "
              data-target="#location-highlights"
            >
              LOCATION
            </a>
          </li>
          <li
            className="nav-item"
            style={{
              listStyle: "none",
            }}
          >
            <a
              className="nav-link text-uppercase text-white"
              href="#why-invest"
              data-target="#why-invest"
            >
              Why invest
            </a>
          </li>
        </ul>
      </nav>
      <MobMySlider className="main-slider-mob" data={MainSliderImages} />
      <Main />
      <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" class="scrollspy-example" tabindex="0">

      <MobileHighlights />
      <Title title="Location Highlights" id="location-highlights" />
      <MobLocation />
      <Title title="Images & Videos" />
      <MobMySlider data={ImageAndVideos} />
      <Title title="WATCH TVC" id="watch-tvc" />
      <MovWatchTVC />
      <Title title="A Beaming Investment" id="why-invest" />
      <MobInvestment />
      <Title title="Facilities & Amenities " />
      <MobMySlider id="facilities-amenities" data={Levish} />
      <Title title="Price Plan " />
      <MobPrice />
      <Title title="Construction Updates " />
      <MobMySlider id="construction-updates" data={Construction} />
      </div>
    </>
  );
}
