import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function Footer() {
    return (
        <div
            className="container-fluid"
            style={{
                background: "#4b2b7c",
            }}
        >
            <div className="row p-md-0 pt-md-2 p-3 justify-content-center">
                <div className="col-md-4 mb-3">
                    <p className="mb-0 link text-uppercase text-white f-24">
                        About Us
                    </p>
                    <ul className="text-white">
                        <li>
                            <a
                                className="f-link"
                                href="https://www.indigodevelopers.com.pk/"
                            >
                                Indigo Developers
                            </a>
                        </li>
                        <li>
                            <a
                                className="f-link"
                                href="https://www.indigodevelopers.com.pk/projects/"
                            >
                                Other Projects
                            </a>
                        </li>
                        <li>
                            <a
                                className="f-link"
                                href="https://www.indigodevelopers.com.pk/contact-us/"
                            >
                                Contact US
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-4 col-md-4 mb-3">
                    <p className="mb-0 link text-uppercase f-24 text-white ">
                        Head office
                    </p>
                    <p class="mb-0  text-white">
                        <span className="me-1">
                            <LocationOnIcon className="text-white" />
                        </span>
                        3 C3 Noor Jehan Rd, Block C 3 Gulberg{" "}
                        <br className="d-lg-block d-none" />{" "}
                        <span className="ps-lg-4 text-white ps-md-0 ">
                            lll, Lahore Punjab 54660
                        </span>
                    </p>
                    <p class="mb-0 text-white ">
                        <span className="me-1">
                            <MailOutlineIcon className="text-white" />
                        </span>{" "}
                        info@indigopak.com
                    </p>
                    <p class="mb-0 text-white ">
                        <span className="me-1">
                            <LocalPhoneIcon className="text-white" />
                        </span>
                        0330 3022222
                    </p>
                </div>
                <div className="col-lg-2 col-md-3 mb-3">
                    <p className="mb-0 link text-uppercase f-24 text-md-end text-white">
                        Contact Us
                    </p>
                    <div className="d-flex justify-content-md-end mt-1">
                        <div className="">
                            <a
                                target="_blank"
                                href="https://www.instagram.com/indigodeveloper/"
                            >
                                <InstagramIcon className="f-34 text-white border rounded-2" />
                            </a>
                        </div>
                        <div className="contact-icons-spacing">
                            <a
                                target="_blank"
                                href="https://www.facebook.com/indigo.developers/"
                            >
                                <FacebookIcon className="f-34 text-white border rounded-2" />
                            </a>
                        </div>
                        <div className="">
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/company/indigo-developers/"
                            >
                                <LinkedInIcon className="f-34 text-white border rounded-2" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
